<template>
  <div class="empty-state">
    <div class="empty-state-wrapper">
      <p class="empty-state-image">
        <slot name="image"></slot>
      </p>

      <div class="empty-state-content">
        <h3 class="heading-4" v-if="heading">{{heading}}</h3>
        <slot name="content"></slot>
      </div>

      <p class="empty-state-cta">
        <PButton @click="onClick" class="is-medium">{{ctaLabel}}</PButton>
      </p>
    </div>
  </div>
</template>

<script>
  import PButton from "@/components/PButton";
  export default {
    name: "PEmptyState",
    components: {PButton},
    props: {
      image: {
        type: String,
        required: false
      },
      heading: {
        type: String,
        default: null
      },
      ctaLabel: {
        type: String,
        default: ''
      }
    },
    methods: {
      onClick() {
        this.$emit('cta-click');
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/variables";

  .empty-state {
    width: 100%;
    padding: 32px 0;

    &-wrapper {
      margin: 0 auto;
      max-width: 400px;
      text-align: center;
    }

    &-image {

      img {
        width: 100%;
        max-width: 200px;
      }
    }

    &-content {
      margin: 0 0 24px;
      color: $secondary-text-color;

      h3 {
        color: #000;
      }
    }
  }
</style>
