<template>
  <router-link active-class="is-active" :to="to" :exact="false">
    <span class="left"><slot></slot></span><span class="right"></span>
  </router-link>
</template>
<script>
  export default {
    props: {
      to: {
        required: true
      }
    }
  }
</script>
<style scoped lang="scss">
  span {
    font-weight: 500;
  }
</style>
