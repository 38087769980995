<template>
  <div class="server-details">
    <p class="breadcrumbs">
      <router-link to="/dashboard">Servers</router-link> <span>›</span>
      <template v-if="server">{{ server.name }}</template>
    </p>

    <PTabs>
      <PTabItem :to="{'name': 'server-details.dashboard'}">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:role="'presentation'" v-bind:focusable="'false'"><defs><path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 2c3.32 0 6 2.68 6 6s-2.68 6-6 6-6-2.68-6-6 2.68-6 6-6zm0 2c-.56 0-1 .44-1 1s.44 1 1 1 1-.44 1-1-.44-1-1-1zM4.68 6a1 1 0 00-.38 1.68L6.12 9.5c-.04.16-.12.32-.12.5 0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2c-.18 0-.34.08-.5.12L5.68 6.3a1 1 0 00-.88-.32 1 1 0 00-.12 0V6zM11 6c-.56 0-1 .44-1 1s.44 1 1 1 1-.44 1-1-.44-1-1-1z" id="a"/></defs><use xlink:href="#a" fill-rule="evenodd"/></svg> Dashboard
      </PTabItem>

      <template v-if="server && server.type === 'TEXT_MODERATION'">
        <PTabItem :to="{'name': 'server-details.blacklist'}">
          <svg width="16" height="16" viewBox="0 0 16 16" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zM3.107 4.522A5.98 5.98 0 002 8c0 3.32 2.68 6 6 6a5.98 5.98 0 003.478-1.107zM8 2a5.98 5.98 0 00-3.478 1.107l8.371 8.371A5.98 5.98 0 0014 8c0-3.32-2.68-6-6-6z"/></svg> Blacklist
        </PTabItem>

        <PTabItem :to="{'name': 'server-details.whitelist'}">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M8.482.051l7.02 1.62.03.763c.353 8.824-2.1 13.54-7.5 13.54S.18 11.258.533 2.434l.03-.763 7.02-1.62a2 2 0 01.9 0zM8.032 2L2.508 3.275c-.164 7.316 1.72 10.699 5.524 10.699 3.804 0 5.689-3.383 5.525-10.7L8.032 2zm2.753 2.81l1.495 1.328-4.705 5.293-3.25-3.25L5.74 6.767l1.75 1.75 3.295-3.708z"/></svg> Whitelist
        </PTabItem>
      </template>

      <template v-if="server && server.type === 'IMAGE_MODERATION'">
        <PTabItem :to="{'name': 'server-details.models'}">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M10 .5l3.995 2.307a3 3 0 011.5 2.598v5.19a3 3 0 01-1.5 2.598L10 15.5a4 4 0 01-4 0l-3.995-2.307a3 3 0 01-1.5-2.598v-5.19a3 3 0 011.5-2.598L6 .5a4 4 0 014 0zm3.495 5.734l-4.49 2.593v4.937l3.99-2.303a1 1 0 00.5-.866v-4.36zm-10.99 4.361a1 1 0 00.5.866l3.99 2.303V8.827l-4.49-2.593v4.361zM7 2.232L3.286 4.376 8 7.098l4.714-2.722L9 2.232a2 2 0 00-2 0z" fill-rule="evenodd"/></svg> Models
        </PTabItem>
      </template>

      <PTabItem :to="{'name': 'server-details.console'}">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M14 0a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h12zm0 6H2v8h12V6zM2 2v2h2V2H2zm4 2h8V2H6v2z" fill-rule="evenodd"/></svg> API Console
      </PTabItem>

      <PTabItem :to="{'name': 'server-details.settings'}">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M9.709.183a1 1 0 01.69.549l.81 1.712 1.887-.155a1 1 0 01.82.324 7.992 7.992 0 011.707 2.961 1 1 0 01-.13.872L14.416 8l1.077 1.556a1 1 0 01.13.874 8.026 8.026 0 01-1.708 2.959 1 1 0 01-.822.324l-1.885-.156-.807 1.708a1 1 0 01-.69.55 7.992 7.992 0 01-3.42.002 1 1 0 01-.69-.549l-.809-1.711-1.886.155a1 1 0 01-.82-.323 7.992 7.992 0 01-1.71-2.963 1 1 0 01.132-.872L1.584 8 .507 6.445a1 1 0 01-.13-.874 8.026 8.026 0 011.707-2.958 1 1 0 01.822-.324l1.886.154.807-1.708a1 1 0 01.69-.55A7.992 7.992 0 019.71.183zM7.187 2.055l-.883 1.869a1 1 0 01-.986.57l-2.061-.17a6.064 6.064 0 00-.812 1.407l1.177 1.7a1 1 0 010 1.138l-1.177 1.7c.205.504.478.977.811 1.408l2.062-.17a1 1 0 01.986.569l.885 1.87a5.992 5.992 0 001.624 0l.883-1.87a1 1 0 01.986-.57l2.06.171a6.064 6.064 0 00.813-1.407l-1.177-1.7a1 1 0 010-1.14l1.177-1.699a5.992 5.992 0 00-.81-1.406l-2.063.168a1 1 0 01-.986-.569l-.885-1.87a5.992 5.992 0 00-1.624.001zM8 5a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"/></svg> Settings
      </PTabItem>
    </PTabs>

    <div class="content">
      <router-view v-if="server" :server="server"></router-view>
      <div v-else class="server-loader">
        <PLoader></PLoader>
      </div>
    </div>
  </div>
</template>

<script>
  import PTabs from "../../components/tabs/PTabs";
  import PTabItem from "../../components/tabs/PTabItem";
  import PLoader from "../../components/loader/PLoader";
  import {GET_SERVER} from "@/dashboard/queries";

  export default {
    components: {
      PTabs,
      PTabItem,
      PLoader,
    },
    data() {
      return {
        server: null
      }
    },
    apollo: {
      server: {
        query: GET_SERVER,
        variables() {
          return {
            id: this.$route.params.server_id
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .server-details {
    max-width: 960px;
    margin: 32px auto;
    padding: 0 16px;

    .content {
      border-radius: 0 6px 6px 6px;
      padding: 24px;
      background: #fff;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    }

    .form-row {
      margin: 16px 0 32px;
    }

    .form-row label {
      display: inline-block;
      margin: 0 0 4px;
    }
  }

  .server-loader {
    padding: 150px;
    text-align: center;
  }
</style>
