<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-header">
      <h5>Test your server</h5>
      <p class="body-small fineprint">Results are based on your current blacklist and whitelist.</p>
    </div>
    <div class="form-row">
      <label for="text">Your text</label><br>
      <PTextField id="text" v-model="text" multiline="8"></PTextField>
    </div>

    <div class="form-row form-row-apart">
      <label for="api">Language</label>
      <select id="api" class="select" v-model="lang">
        <option value="en">en - English</option>
        <option value="es">es - Spanish</option>
      </select>
    </div>

    <!-- <div class="form-row form-row-apart">
      <label><span>Detect leet speak</span></label>
      <PSwitch v-model="leetSpeak"></PSwitch>
    </div> -->

    <div class="form-row">
      <PButton v-bind:disabled="disableSubmit" type="submit" full-width>Submit</PButton>
    </div>
  </form>
</template>

<script>
  import client from "../../api-client";
  // import PSwitch from "../../components/PSwitch";
  import PButton from "../../components/PButton";
  import PTextField from "../../components/PTextField";

  export default {
    name: "ProfanityFilterConsole",
    components: {PButton, PTextField},
    props: {
      server: {
        type: Object,
        required: true
      },
    },

    data () {
      return {
        text: '',
        lang: 'en',
        leetSpeak: false
      }
    },

    computed: {
      disableSubmit () {
        return this.text === '';
      }
    },

    methods: {
      async handleSubmit() {
        this.$emit('loading', true);

        try {
          const result = await client.moderateText(this.server.apiKey, this.text, {
            lang: this.lang,
            leetSpeak: this.leetSpeak
          });

          this.$emit('result', result.data)
        } catch (e) {
          console.log(e);
        }

        this.$emit('loading', false);
      }
    }

  }
</script>
