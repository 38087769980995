<template>
  <div class="api-console">
    <div class="api-console-content">
      <div class="api-console-pane api-console-left-pane">
        <ProfanityFilterConsole v-if="server.type === 'TEXT_MODERATION'" :server="server" @result="updateResult" @loading="updateLoading"></ProfanityFilterConsole>
        <ImageModerationConsole v-if="server.type === 'IMAGE_MODERATION'" :server="server" @result="updateResult" @loading="updateLoading"></ImageModerationConsole>
      </div>

      <div class="api-console-pane api-console-right-pane">
        <template v-if="result">
          <p class="result-label">API Response:</p>
          <div class="result">
            <pre><code v-html="formattedResult"></code></pre>
          </div>
        </template>
        <template v-else>
          <p class="result-label">Submit a request to get started...</p>
        </template>

        <template v-if="loading">
          <div class="api-console-loader">
            <PLoader></PLoader>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfanityFilterConsole from "./console/ProfanityFilterConsole";
  import ImageModerationConsole from "./console/ImageModerationConsole";
  import PLoader from "../components/loader/PLoader";

  export default {
    components: {PLoader, ImageModerationConsole, ProfanityFilterConsole},

    props: {
      server: {
        type: Object,
        required: false
      },
    },

    data() {
      return {
        result: null,
        loading: false
      }
    },

    computed: {
      formattedResult() {
        if (this.result === null) {
          return '';
        }

        return this.highlightResults(JSON.stringify(this.result, null, 2));
      }
    },

    methods: {
      highlightResults(results) {
        const json = results.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
          let cls = 'number';

          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key';
            } else {
              cls = 'string';
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean';
          } else if (/null/.test(match)) {
            cls = 'null';
          }

          return '<span class="' + cls + '">' + match + '</span>';
        });
      },

      updateResult(result) {
        this.result = result
      },

      updateLoading(value) {
        this.loading = value
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/variables";
  
  .api-console {
    margin: 0 auto;
    width: 100%;

    // &-header {
    //   border-bottom: 1px solid #ccc;
    //   padding: 16px;
    // }

    &-content {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      height: 550px;
    }

    &-pane {
      flex: 1;
    }

    &-left-pane {
      border: 1px solid #ddd;
      border-radius: 6px 0 0 6px;
    }

    .form-header {
      margin: 0 auto 24px;
      padding: 12px 16px;
      border-radius: 6px 0 0 0;
      border-bottom: 1px solid #ddd;
      background: $secondary-background-color;

      h5 {
        margin: 0;
      }

      p {
        margin: 2px auto;
      }
    }

    .form-row {
      padding: 0 16px;
      margin: 16px auto;

      &:last-child {
        margin: 24px auto 0;
        border-bottom: none;
      }

      label {
        margin: 0 0 6px;
        font-weight: 500;
        display: inline-block;
      }
    }

    .form-row-apart {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      label {
        margin: 0;
      }
    }
    &-loader {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(#000, 0.8);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .api-console-right-pane {
    position: relative;
    padding: 16px;
    background: #272822;
    font-size: 16px;
    line-height: 1.5em;
    overflow: scroll;

    .result-label {
      color: rgba(#FFFFFF, 0.6);
      margin: 0 0 8px;
    }

    .result {
      color: #FFFFFF;

      code {
        font-family: Monaco, "Courier New", monospace;
        font-size: 14px;
        line-height: 1.25em;
      }

      pre {
        padding: 0 16px;
        margin: 0;
      }

      .key {
        color: #FFFFFF;
      }

      .string {
        color: #A9FF60;
      }

      .number {
        color: #FF73FD;
        font-weight: bold;
      }

      .boolean, .null {
        color: #9ACC98;
      }

    }
  }
</style>
