<template>
  <div class="dashboard-metric-widget">
    <span class="title body-small">{{title}}</span>
    <p class="value">
      {{formattedValue}}<span v-if="percent" class="percent">%</span>
    </p>

    <p class="dashboard-metric-widget-change" :class="changeClass">
      <svg width="16px" height="16px">
        <circle opacity="0.1" cx="8" cy="8" r="8"></circle>
        <polygon points="6.40900974 5.59834957 11.0052038 5.24479618 10.6516504 9.84099026 8.88388348 8.0732233 5.34834957 11.6087572 4.64124279 10.9016504 8.1767767 7.36611652"></polygon>
      </svg>
      {{formattedChange}}
    </p>
  </div>
</template>
<script>
  const formatter = Intl.NumberFormat(['en-US', 'en']);

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      value: {
        type: Number,
        required: false,
        default: 0
      },
      delta: {
        type: Number,
        required: false,
        default: 0
      },
      percent: {
        type: Boolean,
        required: false,
        default: false
      },
      reversed: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      formattedValue() {
        return formatter.format(this.value);
      },

      formattedChange() {
        if (this.delta === 0) {
          return '-';
        }
        return formatter.format(Math.abs(this.delta)) + '%';
      },

      changeClass() {
        return {
          'is-reversed': this.reversed,
          'is-neutral': this.delta === 0,
          'is-positive': this.delta > 0,
          'is-negative': this.delta < 0
        };
      }
    }

  }
</script>

<style lang="scss">
  @import "src/scss/variables";

  .dashboard-metric-widget {
    border: 1px solid $border-color;
    border-radius: 6px;
    text-align: center;
    min-width: 240px;
    display: inline-block;
    box-sizing: border-box;
    padding: 16px;
    margin: 0 24px;

    &:first-child { margin-left: 0; }
    &:last-child  { margin-right: 0; }

    .title {
      color: $secondary-text-color;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: -0.2px;
      margin: 0;
      padding: 0;
    }

    .value {
      color: rgba(#000, 0.8);
      font-size: 40px;
      font-weight: 500;
      margin: 4px 0;

      .percent {
        font-size: 30px;
        vertical-align: middle;
      }
    }

    &-change {
      justify-content: center;
      align-items: center;
      display: flex;
      color: #333;
      fill: #333;
      min-height: 16px;
      font-size: 14px;
      margin: 0;

      svg {
        margin: 0 4px 0 0;
      }

      &.is-neutral svg {
        display: none;
      }

      &.is-positive {
        color: #009872;
        fill: #009872;
        transform: none;
      }

      &.is-negative {
        color: #FF5C00;
        fill: #FF5C00;
        svg {
          transform: scale(-1, 1) scale(1, -1);
        }
      }

      // Flip the colors
      &.is-reversed.is-positive {
        color: #FF5C00;
        fill: #FF5C00;
      }

      &.is-reversed.is-negative {
        color: #009872;
        fill: #009872;
      }
    }
  }
</style>
