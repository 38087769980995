<template>
  <div>
    <div class="dashboard-row">
      <h5>Daily Summary</h5>
      <div class="dashboard-metric-widget-cluster">
        <DashboardMetricWidget title="Total Tasks" :value="stats.totalRequests.today" :delta="stats.totalRequests.change"></DashboardMetricWidget>
<!--        <DashboardMetricWidget title="Profanity Rate" :value="stats.profanityRate.today" :delta="stats.profanityRate.change"></DashboardMetricWidget>-->
      </div>
    </div>
    <div class="dashboard-row">
      <h5>Usage Trend</h5>
      <Chart :labels="stats.usage.labels" :calls="stats.usage.calls" :timespan="timespan" @updated="updatedTimespan"></Chart>
    </div>
  </div>
</template>

<script>
  const Chart = () => import("./Chart");
  import DashboardMetricWidget from "./DashboardMetricWidget";
  import {GET_SERVER_STATS} from "@/dashboard/queries";

  export default {
    components: {
      Chart,
      DashboardMetricWidget
    },
    props: {
      server: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        timespan: '7d',
        stats: {
          totalRequests: {today: 0,  change: 0},
          // profanityRate: {today: 0,  change: 0},
          usage: {
            labels: [],
            calls: []
          }
        }
      }
    },
    apollo: {
      stats: {
        query: GET_SERVER_STATS,
        variables() {
          return {
            serverId: this.server.id,
            timespan: this.timespan
          }
        }
      },
    },
    methods: {
      updatedTimespan(timespan) {
        this.timespan = timespan;
      }
    },
  }
</script>
<style lang="scss">
  .dashboard-row {
    margin-bottom: 40px;
  }
</style>
