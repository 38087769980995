<template>
  <div class="delayed-loader">
    <PLoader></PLoader>
  </div>
</template>

<script>
  import PLoader from "@/components/loader/PLoader";

  export default {
    name: "DelayedLoader",
    components: {PLoader}
  }
</script>

<style lang="scss">
  .delayed-loader {
    background: #dddddd;
    text-align: center;
    padding: 64px 0;
    animation-duration: 0.5s;
    animation-name: delayed-loader-animation;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes delayed-loader-animation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
</style>
