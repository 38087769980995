<template>
  <button class="round-button" v-on="$listeners" :class="{'round-button-destructive': destructive}">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: "PButton",
    inheritAttrs: false,
    props: {
      destructive: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "src/scss/variables";

  .round-button {
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 0;
    background: $brand-color;
    box-shadow: 0 1px 4px rgba(#000, 0.2);
    fill: #ffffff;
    cursor: pointer;
    transition: transform 0.2s ease-out;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(3, 102, 214, 0.3);
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(#000, 0.1);
      background-color: darken($brand-color, 5%);
    }

    &:active { background-color: darken($brand-color, 10%); }

    &-destructive {
      background: $alert-color;

      &:hover { background-color: darken($alert-color, 5%); }
      &:active { background-color: darken($alert-color, 10%); }
    }
  }
</style>
