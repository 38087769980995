<template>
  <PModal title="Update Name" tag="form" v-bind="this.$attrs" v-on="this.$listeners" @submit.prevent="onSubmit">
    <PFormField label="Server name">
      <PTextField v-model="name"></PTextField>
    </PFormField>

    <template v-slot:actions>
      <PButton @click="cancel" class="button-secondary">Cancel</PButton>
      <PButton type="submit">Save</PButton>
    </template>
  </PModal>
</template>

<script>
  import PModal from "../../components/PModal";
  import PButton from "../../components/PButton";
  import {RENAME_SERVER} from "@/dashboard/modals/mutations";
  import PFormField from "@/components/PFormField";
  import PTextField from "@/components/PTextField";

  export default {
    components: {PTextField, PFormField, PModal, PButton},
    props: {
      server: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        name: ''
      }
    },
    mounted() {
      this.name = this.server.name;
    },
    methods: {
      cancel() {
        this.$emit('close');
      },
      async onSubmit() {
        const _ = await this.$apollo.mutate({
          mutation: RENAME_SERVER,
          variables: {
            serverId: this.server.id,
            newName: this.name
          }
        });

        this.$emit('close');
      },
    }
  }
</script>
