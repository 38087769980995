<template>
  <div>
    <CreateModerationRuleModal v-if="showCreateRuleModal" @close="closeModals" :type="type"></CreateModerationRuleModal>

    <DelayedLoader v-if="loading && textModerationRules.items.length === 0"></DelayedLoader>
    <div v-else-if="textModerationRules.items.length > 0" class="data-table">
      <PRoundButton v-if="selectedRules.length > 0" @click="onBatchDeleteClick" destructive>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M18 9l-.917 10.09a1 1 0 01-.996.91H7.913a1 1 0 01-.996-.91L6 9h12zm-5-5c1.1 0 2 .9 2 2h2c1.1 0 2 .9 2 2H5c0-1.1.9-2 2-2h2c0-1.1.9-2 2-2z"/></svg>
      </PRoundButton>
      <PRoundButton v-else @click="createModerationRule">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M13 5v6h6v2h-6v6h-2v-6H5v-2h6V5z"/></svg>
      </PRoundButton>

      <table class="zebra-table">
        <thead>
        <tr>
          <th>
            <PCheckBox v-model="allSelected"></PCheckBox>
          </th>
          <th width="100%">Words ({{textModerationRules.totalItems}})</th>
          <th width="24"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="rule in textModerationRules.items" :key="rule.id" :class="rowClass(rule.id)">
          <td>
            <PCheckBox v-model="selectedRules" :value="rule.id"></PCheckBox>
          </td>
          <td>{{rule.word}}</td>
          <td>
            <PActionButton title="Delete word" @click="deleteRule(rule.id)">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M18 9l-.917 10.09a1 1 0 01-.996.91H7.913a1 1 0 01-.996-.91L6 9h12zm-5-5c1.1 0 2 .9 2 2h2c1.1 0 2 .9 2 2H5c0-1.1.9-2 2-2h2c0-1.1.9-2 2-2z"/></svg>
            </PActionButton>
          </td>
        </tr>
        </tbody>
      </table>

      <PPagination :results="textModerationRules"></PPagination>
    </div>

    <PEmptyState v-else :heading="type === 'WHITELIST' ? 'Your whitelist is empty.' : 'Your blacklist is empty.'" cta-label="Add Word" @cta-click="createModerationRule">
      <template v-slot:image>
        <img v-if="type === 'WHITELIST'" src="@/assets/whitelist-blank-state.svg" alt="">
        <img v-else src="@/assets/blacklist-blank-state.svg" alt="">
      </template>
      <template v-slot:content>
        <p v-if="type === 'WHITELIST'">
          Add words that are acceptable to you, but are considered profane by our default model.
        </p>
        <p v-else>
          Add additional toxic words to block.
        </p>
      </template>
    </PEmptyState>
  </div>
</template>

<script>
  import PCheckBox from "@/components/PCheckBox";
  import PEmptyState from "@/components/PEmptyState";
  import PPagination from "@/components/PPagination";
  import PRoundButton from "@/components/PRoundButton";
  import PActionButton from "@/components/PActionButton";
  import {GET_TEXT_MODERATION_RULES} from "@/dashboard/queries";
  import {DELETE_TEXT_MODERATION_RULES} from "@/dashboard/mutations";
  import {pluralize} from "@/lib/text";
  import DelayedLoader from "@/dashboard/DelayedLoader";

  export default {
    name: "TextModerationRulesManagement",
    components: {
      DelayedLoader,
      PCheckBox,
      PEmptyState,
      PPagination,
      PRoundButton,
      PActionButton,
    },
    props: {
      type: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showCreateRuleModal: false,
        allSelected: false,
        selectedRules: [],
        textModerationRules: {
          items: []
        },
        deleteRuleMessage: null
      }
    },
    computed: {
      loading() {
        return this.$apollo.queries.textModerationRules.loading;
      }
    },
    watch: {
      allSelected(newValue, oldValue) {
        if (newValue) {
          this.selectedRules = this.textModerationRules.items.map((item) => item.id);
        } else {
          this.selectedRules = [];
        }
      }
    },
    apollo: {
      textModerationRules: {
        query: GET_TEXT_MODERATION_RULES,
        variables() {
          return {
            type: this.type,
            serverId: this.$route.params['server_id'],
            page: parseInt(this.$route.query['page'] || 1, 10)
          };
        }
      }
    },
    methods: {
      rowClass(ruleId) {
        return this.selectedRules.includes(ruleId) ? 'selected' : false;
      },
      closeModals() {
        this.showCreateRuleModal = false;
      },
      createModerationRule() {
        this.showCreateRuleModal = true;
      },
      onBatchDeleteClick() {
        this.batchDeleteRules(this.selectedRules);
      },
      deleteRule(ruleId) {
        this.batchDeleteRules([ruleId]);
      },
      batchDeleteRules(ruleIds) {
        const title = pluralize(ruleIds.length,
          'Delete word from ' + this.type.toLowerCase() + '?',
          'Delete words from ' + this.type.toLowerCase() + '?');

        switch (this.type) {
          case "BLACKLIST":
            this.deleteRuleMessage = 'Once words are deleted from the blacklist, they are no longer guaranteed to be blocked.';
            break;
          case "WHITELIST":
            this.deleteRuleMessage = 'Once words are deleted from the whitelist, they will no longer be immune to moderation.';
            break;
        }

        this.$profanitor.prompt({
          title: title,
          message: this.deleteRuleMessage,
          destructive: true,
          confirmationCTA: "Delete"
        }).then((confirmed) => {
          if (confirmed) {
            return this.$apollo.mutate({
              mutation: DELETE_TEXT_MODERATION_RULES,
              refetchQueries: ['GetTextModerationRules'],
              variables: {
                ruleIds
              }
            }).then(() => {
              // Reset selected items
              this.allSelected = false;
              this.selectedRules = [];
            });
          }
        });
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/variables";

  .data-table {
    position: relative;

    .round-button {
      float: right;
      margin: 0 24px -20px 0;
    }
  }

  .zebra-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th, td {
      padding: 8px 24px;
      border: 1px solid rgba(#000, 0.2);
      text-align: left;
    }

    th {
      padding: 16px 24px;
    }

    th:not(:last-child) { border-right: 0; }
    th:not(:first-child) { border-left: 0; }

    td:not(:last-child) { border-right: 0; }
    td:not(:first-child) { border-left: 0; }

    tr td { border-top: 0; }
    tr:not(:last-child) td { border-bottom: 0; }
    tr:last-child {
      td:first-child { border-radius: 0 0 0 6px; }
      td:last-child { border-radius: 0 0 6px 0; }
      td:only-child { border-radius: 0 0 6px 6px; }
    }

    // corner rounding
    th:first-child { border-radius: 6px 0 0 0; }
    th:last-child { border-radius: 0 6px 0 0; }
    th:only-child{ border-radius: 6px 6px 0 0; }

    tbody tr:nth-child(odd) {
      background: $secondary-background-color;
    }

    tbody tr.selected {
      background-color: $brand-color-light90;
    }
  }

  .actionable-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0 16px;
  }

  .category {
    background: rgba(#000, 0.05);
    padding: 2px 8px;
    border-radius: 4px;
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
</style>
