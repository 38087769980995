<template>
  <div class="pagination">
    <span class="body-small">Page {{results.page}} of {{results.totalPages}}</span>

    <div>
      <router-link v-if="results.hasPrev" :to="{query: {page: results.page - 1}}" class="pagination-button" rel="prev">‹ Prev</router-link>
      <span v-else class="pagination-button disabled">‹ Prev</span>

      <router-link v-if="results.hasNext" :to="{query: {page: results.page + 1}}" class="pagination-button" rel="next">Next ›</router-link>
      <span v-else class="pagination-button disabled">Next ›</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PPagination",
    props: {
      results: {
        type: Object,
        required: true
      },
    },
  }
</script>

<style lang="scss">
  .pagination {
    color: rgba(#000, 0.6);
    display: flex;
    margin: 16px 0;
    align-items: center;
    justify-content: space-between;

    &-button {
      user-select: none;
      box-sizing: border-box;
      color: rgba(#000, 0.8);
      text-decoration: none;
      border: 1px solid rgba(#000, 0.2);
      border-radius: 6px;
      padding: 6px 12px;
      display: inline-block;

      &:first-child {
        margin-right: 8px;
      }

      &.disabled, &.disabled:hover {
        opacity: 0.4;
        background: rgba(#000, 0.1);
      }

      &:hover {
        color: rgba(#000, 0.8);
        border-color: rgba(#000, 0.4);
      }
    }
  }
</style>
