import gql from "graphql-tag";

export const GET_SERVER = gql`
  query GetServer($id: ID!) {
    server(id: $id) {
      id
      name
      type
      apiKey
      operationsToday
    }
  }
`;

export const GET_SERVER_STATS = gql`
  query GetServerStats($serverId: ID!, $timespan: String!) {
    stats: serverStats(serverId: $serverId, timespan: $timespan) {
      totalRequests {
        today
        yesterday
        change
      }
      profanityRate {
        today
        yesterday
        change
      }
      usage {
        labels
        calls
      }
    }
  }
`;

export const GET_TEXT_MODERATION_RULES = gql`
  query GetTextModerationRules($serverId: ID!, $type: TextModerationRuleType!, $page: Int!) {
    textModerationRules(serverId: $serverId, type: $type, page: $page) {
      page
      totalPages
      totalItems
      hasPrev
      hasNext
      items {
        id
        type
        word
      }
    }
  }
`;
