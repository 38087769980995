<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-header">
      <h5>Test your server</h5>
      <p class="body-small fineprint">Results are based on your current
        <router-link :to="{'name':'server-details.models'}">model settings.</router-link></p>
    </div>
    <template v-if="imageInput">
      <div class="form-row">
        <p>
          <label for="file">Image file</label><br>
          <PImageInput id="file" v-model="image"></PImageInput>
        </p>
      </div>
      <div class="form-row">
        <a role="button" href="#" @click="switchInputType">Upload from URL (recommended)</a>
      </div>
    </template>
    <template v-else>
      <div class="form-row">
        <p>
          <label for="url">Image URL</label><br>
          <input class="text-field" type="text" id="url" v-model="url" placeholder="https://example.com/uploads/photo-1.jpg">
        </p>
      </div>
      <div class="form-row">
        <a role="button" href="#" @click="switchInputType">Upload from file</a>
      </div>
    </template>
    <div class="form-row">
      <PButton v-bind:disabled="disableSubmit" type="submit" full-width>Submit</PButton>
    </div>
  </form>
</template>

<script>
  import client from "@/api-client";
  import {extractErrorMessage} from "@/lib/error-handler";
  import PButton from "@/components/PButton";
  import PImageInput from "@/components/image-input/PImageInput";

  export default {
    name: "ImageModerationConsole",
    components: {PImageInput, PButton},
    props: {
      server: {
        type: Object,
        required: true
      },
    },
    data () {
      return {
        url: '',
        image: null,
        imageInput: false
      }
    },

    computed: {
      disableSubmit() {
        const hasURL = this.url.startsWith('http://') || this.url.startsWith('https://');
        return !hasURL && this.image == null;
      }
    },

    methods: {
      switchInputType() {
        this.imageInput = (this.imageInput == false)? true : false;
      },
      async handleSubmit() {
        this.$emit('loading', true);

        try {
          if (this.image !== null) {
            const result = await client.imageModeration(this.server.apiKey, {
              image: this.image
            });

            this.$emit('result', result.data)
          } else {
            const result = await client.imageModeration(this.server.apiKey, {
              url: this.url
            });

            this.$emit('result', result.data)
          }
        } catch (e) {
          this.$profanitor.toast(extractErrorMessage(e), {type: 'error'});
          this.$emit('result', null)
        } finally {
          this.$emit('loading', false);
        }
      }
    }

  }
</script>
