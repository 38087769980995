<template>
  <span class="code-box">
    <code><slot></slot></code>
    <button title="Copy" @click="onCopy">
      <transition name="code-box-toast-transition">
        <span v-if="showToast" class="code-box-toast">Copied!</span>
      </transition>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M19 7a2 2 0 012 2v10a2 2 0 01-2 2H9a2 2 0 01-2-2V9a2 2 0 012-2h10zm0 2H9v10h10V9zm-4-6a2 2 0 012 2v1h-2V5H5v10h1v2H5a2 2 0 01-2-2V5a2 2 0 012-2h10z"/></svg>
    </button>
  </span>
</template>

<script>
  import * as clipboard from "clipboard-polyfill";

  export default {
    components: {},

    data() {
      return {
        showToast: false
      }
    },

    methods: {
      onCopy(event) {
        event.preventDefault();

        clipboard.writeText(this.$slots.default[0].text).then(() => {
          this.presentToast();
          this.$emit('copied')
        })
      },

      presentToast() {
        if (!this.showToast) {
          this.showToast = true;
          window.setTimeout(() => { this.showToast = false; }, 1000);
        }
      }
    },
  }
</script>

<style lang="scss">
  .code-box {
    font-family: Monaco, "Courier New", monospace;
    background: #eee;
    display: inline-flex;
    padding: 8px 8px 8px 16px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;

    code {
      flex: 1;
      margin: 0 8px 0 0;
    }

    button {
      box-sizing: border-box;
      position: relative;
      border: 0;
      background: none;
      fill: #464848;
      width: 32px;
      height: 32px;
      padding: 4px;
      outline: none;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background: rgba(#000, 0.1);
      }

      &:active {
        background: #999;
      }
    }

    &-toast {
      font-size: 14px;
      position: absolute;
      top: -32px;
      left: 50%;
      margin-left: -100%;
      background: rgba(#000, 0.8);
      padding: 4px 8px;
      border-radius: 4px;
      color: #fff;
    }
  }

  .code-box-toast-transition-enter-active, .code-box-toast-transition-leave-active {
    transition: all 0.3s;
    opacity: 1.0;
    transform: none;
  }

  .code-box-toast-transition-enter, .code-box-toast-transition-leave-to {
    opacity: 0;
    transform: translate3d(0, 4px, 0);
  }
</style>
