<template>
  <PModal title="Delete Server" tag="form" @submit.prevent="onSubmit" v-bind="this.$attrs" v-on="this.$listeners">
    <p>
      Are you sure you want to delete <strong>{{server.name}}</strong>?
      This action <strong>cannot</strong> be undone.
    </p>

    <p>Please type <strong>DELETE</strong> to confirm:</p>

    <p>
      <PTextField v-model="confirmation"></PTextField>
    </p>

    <template v-slot:actions>
      <PButton :disabled="!confirmed" type="submit" destructive>Delete this server</PButton>
    </template>
  </PModal>
</template>

<script>
  import PModal from "../../components/PModal";
  import PButton from "../../components/PButton";
  import PTextField from "@/components/PTextField";
  import {DELETE_SERVER} from "@/dashboard/modals/mutations";

  export default {
    components: {PTextField, PModal, PButton},
    props: {
      server: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        confirmation: ''
      }
    },
    computed: {
      confirmed() {
        return this.confirmation.toLowerCase() === 'delete';
      }
    },
    methods: {
      async onSubmit() {
        const result = await this.$apollo.mutate({
          mutation: DELETE_SERVER,
          variables: {
            serverId: this.server.id,
            newName: this.name
          }
        });

        await this.$router.push('/dashboard')
      },
    }
  }
</script>
