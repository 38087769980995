<template>
  <div class="server-settings">
    <DeleteServerModal :server="server" v-show="showDeleteServerModal" @close="onModalClose"/>
    <EditServerNameModal :server="server" v-show="showEditServerNameModal" @updated="onServerUpdated" @close="onModalClose"/>

    <div class="server-settings-row">
      <h5>Name</h5>
      <p>
        <span class="server-settings-row-content">
          {{server.name}}
          <a role="button" href="#" @click="changeServerName">Edit</a>
        </span>
      </p>
    </div>

    <div class="server-settings-row">
      <h5>API Key</h5>
      <p>
        <PCodeBox>{{server.apiKey}}</PCodeBox>
      </p>
    </div>

    <hr>

    <p><PButton @click="deleteServer" destructive class="button is-medium">Delete Server</PButton></p>
  </div>
</template>
<script>
import PButton from "../components/PButton";
import PCodeBox from "../components/PCodeBox";
import DeleteServerModal from "./modals/DeleteServerModal";
import EditServerNameModal from "./modals/EditServerNameModal";

export default {
  components: {
    PButton,
    PCodeBox,
    DeleteServerModal,
    EditServerNameModal
  },

  props: {
    server: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showDeleteServerModal: false,
      showEditServerNameModal: false
    }
  },

  methods: {
    deleteServer() {
      this.showDeleteServerModal = true;
    },

    changeServerName() {
      this.showEditServerNameModal = true;
    },

    onModalClose() {
      this.showDeleteServerModal = false;
      this.showEditServerNameModal = false;
    },

    onServerUpdated(server) {
      this.$emit('updated', server);
      this.showEditServerNameModal = false;
    }

  }
}
</script>
<style lang="scss">
  @import "../scss/variables";

  .server-settings-row {
    margin: 0 0 40px;

    &-content {
      display: inline-flex;
      border-radius: 6px;
      border: 1px solid $border-color;
      padding: 16px;

      a {
        margin-left: 100px;
      }
    }

    p {
      margin: 0;
    }
  }
</style>
