import gql from 'graphql-tag';

export const GET_IMAGE_MODERATION_SERVER_MODEL_DATA = gql`
  query GetImageModerationServerModelData($serverId: ID!) {
    modelIds: imageModerationServerModelIds(serverId: $serverId)

    categories: imageModerationCategories {
      id
      name
      models {
        id
        name
        cost
      }
    }
  }
`;
