<template>
  <div>
    <DelayedLoader v-if="categories.length === 0"></DelayedLoader>
    <template v-else>
      <h5>Select all models you are interested in</h5>

      <div class="models-tab-row">
        <div class="form-row-subrow" v-for="category in categories" :key="category.id">
          <!-- <p>{{category.name}}:</p> -->
          <PModelPicker v-for="model in category.models" :key="model.id" :value="model.id" v-model="modelIds" @change="changed = true">
            {{model.name}}
          </PModelPicker>
        </div>
      </div>

      <div class="models-tab-row">
        <p>Task count for each API call: <strong>{{totalCost}} tasks</strong></p>
      </div>

      <hr>
      <p>
        <PButton :loading="saving" :disabled="disableSaveButton" @click="saveChanges" class="is-medium">Update Model</PButton>
      </p>
    </template>
  </div>
</template>

<script>
  import PButton from "@/components/PButton";
  import PModelPicker from "@/components/PModelPicker";
  import {GET_IMAGE_MODERATION_SERVER_MODEL_DATA} from "./queries";
  import {UPDATE_IMAGE_MODERATION_SERVER_MODELS} from "@/screens/server-details/tabs/models/mutations";
  import DelayedLoader from "@/dashboard/DelayedLoader";

  export default {
    name: "ImageModerationModelsTab",
    components: {DelayedLoader, PModelPicker, PButton},
    props: {
      server: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        saving: false,
        changed: false,
        modelIds: [],
        categories: []
      }
    },
    computed: {
      disableSaveButton() {
        return this.modelIds.length === 0 || this.saving || !this.changed;
      },
      totalCost() {
        let cost = 0;

        this.categories.forEach((category) => {
          category.models.forEach((model) => {
            if (this.modelIds.includes(model.id)) {
              cost += model.cost;
            }
          })
        });

        return cost;
      }
    },
    apollo: {
      $query: {
        fetchPolicy: 'no-cache',
        variables() {
          return {
            serverId: this.server.id
          }
        }
      },
      modelIds: GET_IMAGE_MODERATION_SERVER_MODEL_DATA,
      categories: GET_IMAGE_MODERATION_SERVER_MODEL_DATA,
    },
    methods: {
      saveChanges() {
        this.saving = true;

        this.$apollo.mutate({
          mutation: UPDATE_IMAGE_MODERATION_SERVER_MODELS,
          variables: {
            serverId: this.server.id,
            modelIds: this.modelIds
          }
        }).then(() => {
          this.changed = false;
          this.$profanitor.toast('Model updated!', {type: 'success'});
        }).finally(() => {
          this.saving = false;
        });
      }
    },
  }
</script>
<style lang="scss">
  .models-tab-row {
    margin: 0 0 40px;
  }

  .form-row-subrow {
    margin: 16px 0;

    > * {
      margin-right: 8px;
    }
  }
</style>
