function extractGraphQLError(error) {
  const {graphQLErrors, networkError} = error;

  if (networkError) {
    return `A network error has occurred. Please try again. (${networkError.message})`;
  }

  const userFacingErrorCodes = ['BAD_USER_INPUT', 'NOT_FOUND', 'FORBIDDEN'];

  const userFacingError = graphQLErrors.find((e) => {
    return e.extensions !== undefined && userFacingErrorCodes.includes(e.extensions.code)
  });

  if (userFacingError) {
    return userFacingError.message
  }

  return "An unknown error has occurred. Please try again.";
}

function extractAxiosError(error) {
  if (error.response.data.type == 'FILE_FORMAT_ERROR') {
    return "The file format is not supported. ";
  } else {
    return "An unknown error has occurred. Please try again.";
  }
}

function extractErrorMessage(error) {
  const isAxiosError = error.isAxiosError !== undefined && error.isAxiosError;
  return isAxiosError ? extractAxiosError(error) : extractGraphQLError(error);
}

export {
  extractErrorMessage
};