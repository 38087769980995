<template>
  <div tabindex="0" class="image-input" @dragover="allowDrop" @drop="onDrop" @keydown="onKeyDown" @dblclick="promptForFile">
    <div v-if="previewData" class="image-input-content">
      <button tabindex="0" aria-label="Clear" class="image-input-clear-button" @click="clear">
        <img src="./image-input-clear-button.svg" alt="Clear">
      </button>
      <img class="image-input-content-preview" :src="previewData" draggable="false" alt="Image">
    </div>
    <div v-else class="image-input-placeholder" @click="promptForFile">
      <p>
        <img src="./image-input-placeholder.svg" draggable="false" alt="">
      </p>
      <p>
        Drag &amp; Drop or Browse<br>
        <span class="body-small">(Format: JPEG, PNG, WebP, GIF, BMP)</span>
      </p>
    </div>
  </div>
</template>

<script>
  function doStuff(event) {
    this.previewData = event.target.result;
  }

  export default {
    name: "PImageInput",
    props: ['value'],
    data() {
      return {
        acceptedTypes: ['.jpg', '.jpeg', '.gif',  '.png',  '.webp'],
        previewData: null
      }
    },
    methods: {
      allowDrop(e) {
        e.preventDefault();
      },

      onDrop(e) {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        const extension = '.' + file.name.split('.').pop();

        if (this.acceptedTypes.indexOf(extension) >= 0) {
          this.loadFile(file);
        }
      },

      onKeyDown(event) {
        if (event.keyCode === 8) {
          this.clear(event)
        } else if (event.keyCode === 32) {
          this.promptForFile(event)
        }
      },

      loadFile(file) {
        this.loadPreview(file);
        this.loadImageData(file);
      },

      promptForFile(event) {
        event.preventDefault();
        event.stopPropagation();

        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', 'file');
        inputElement.setAttribute('accept', this.acceptedTypes.join());

        inputElement.onchange = (e) => {
          this.loadFile(e.target.files[0]);
        };

        inputElement.click();
      },

      clear(event) {
        event.preventDefault();
        event.stopPropagation();

        this.previewData = null;
        this.$emit('input', null);
      },

      loadPreview(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewData = e.target.result;
        };

        reader.readAsDataURL(file);
      },

      loadImageData(file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const encodedData = btoa(String(e.target.result));
          this.$emit('input', encodedData);
        };

        reader.readAsBinaryString(file);
      }
    },
  }
</script>

<style lang="scss">
  .image-input {
    position: relative;
    height: 220px;
    text-align: center;
    user-select: none;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;

    &:focus {
      border-radius: 6px;
      outline: none;
      box-shadow: 0 0 0 2px rgba(3, 102, 214, 0.3);
    }

    &-placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 6px;
      border: 1px dashed rgba(#000, 0.4);
      color: rgba(#000, 0.6);
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        border-color: rgba(#000, 0.8);
        color: rgba(#000, 0.8);
      }

      &:focus {
        border: 1px solid #3273dc;
      }
    }

    &-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      line-height: 0;
      background: #000000;
      border: 1px solid #ddd;
      border-radius: 6px;
      overflow: hidden;

      &-preview {
        height: 100%;
        width: 100%;
        object-fit: contain;
        cursor: auto;
      }
    }

    &-clear-button {
      outline: none;
      cursor: pointer;
      padding: 0;
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      background: #F2F1F4;

      &:hover {
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(#000, 0.2);
      }
    }
  }
</style>
