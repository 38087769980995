<template>
  <div class="checkbox" :class="classes" @click="onClick"></div>
</template>

<script>
  export default {
    name: "PCheckBox",
    props: {
      value: {
        default: null
      },
      modelValue: {
        default: false
      },
      trueValue: {
        default: true
      },
      falseValue: {
        default: false
      }
    },
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    computed: {
      shouldBeChecked() {
        if (this.modelValue instanceof Array) {
          return this.modelValue.includes(this.value);
        }

        return this.modelValue === this.trueValue;
      },
      classes() {
        return {
          'checkbox-checked': this.shouldBeChecked
        };
      }
    },
    methods: {
      onClick() {
        const checked = !this.shouldBeChecked;

        if (this.modelValue instanceof Array) {
          const newValue = [...this.modelValue];

          if (checked) {
            newValue.push(this.value);
          } else {
            newValue.splice(newValue.indexOf(this.value), 1);
          }

          this.$emit('change', newValue)
        } else {
          this.$emit('change', checked ? this.trueValue : this.falseValue)
        }
      },
    },
  }
</script>

<style lang="scss">
  @import "../scss/variables";

  .checkbox {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    padding: 2px;
    user-select: none;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 2px;
      display: block;
      box-sizing: border-box;
      border: 2px solid #999;
    }

    &:hover::after {
      border-color: $secondary-text-color;
    }

    &-checked::after {
      border: 0;
      background: $brand-color url("../assets/checkbox-checkmark.svg") no-repeat center center;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
    }
  }
</style>
