import gql from "graphql-tag/src";

export const RENAME_SERVER = gql`
  mutation RenameServer($serverId: ID!, $newName: String!) {
    renameServer(serverId:$serverId, newName:$newName) {
      id
      name
    }
  }
`;

export const DELETE_SERVER = gql`
  mutation DeleteServer($serverId: ID!) {
    deleteServer(serverId:$serverId)
  }
`;
