<template>
  <div
    class="model-picker"
    :class="classes"
    @click="onClick"
    @keydown="onKeyDown"
    role="checkbox"
    tabindex=0
    :aria-checked="shouldBeChecked"
  >
    <span>
      <slot>Label</slot><img :src="icon" alt="" aria-hidden="true">
    </span>
  </div>
</template>

<script>
  import addIcon from "@/assets/icons/icon-add.svg";
  import checkmarkIcon from "@/assets/icons/icon-checkmark.svg";

  export default {
    name: 'PModelPicker',
    props: {
      value: {
        default: null
      },
      modelValue: {
        default: false
      },
      trueValue: {
        default: true
      },
      falseValue: {
        default: false
      }
    },
    model: {
      prop: "modelValue",
      event: "change"
    },
    computed: {
      shouldBeChecked() {
        if (this.modelValue instanceof Array) {
          return this.modelValue.includes(this.value);
        }

        return this.modelValue === this.trueValue;
      },
      classes() {
        return {
          "model-picker-selected": this.shouldBeChecked
        };
      },
      icon() {
        return this.shouldBeChecked ? checkmarkIcon : addIcon;
      },
    },
    methods: {
      onClick(event) {
        this.toggle();
        event.preventDefault();
        event.stopPropagation();
      },
      onKeyDown(event) {
        if (event.keyCode === 32) {
          this.toggle();
          event.preventDefault();
          event.stopPropagation();
        }
      },
      toggle() {
        const checked = !this.shouldBeChecked;

        if (this.modelValue instanceof Array) {
          const newValue = [...this.modelValue];

          if (checked) {
            newValue.push(this.value);
          } else {
            newValue.splice(newValue.indexOf(this.value), 1);
          }

          this.$emit('change', newValue)
        } else {
          this.$emit('change', checked ? this.trueValue : this.falseValue)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/variables";

  .model-picker {
    cursor: pointer;
    display: inline-block;
    color: $brand-color;
    padding: 6px 16px;
    border: 1px solid $brand-color;
    border-radius: 19px;
    background-color: #fff;
    transition: all 0.1s ease-out;
    user-select: none;

    img {
      margin-left: 6px;
      position: relative;
    }

    &:hover {
      background-color: $brand-color-light90;
    }

    &-selected {
      color: #fff;
      background-color: $brand-color;

      &:hover {
        border-color: lighten($brand-color, 3%);
        background-color: lighten($brand-color, 3%);
      }
    }
  }
</style>
