<template>
  <div>
    <TextModerationRulesManagement type="WHITELIST"></TextModerationRulesManagement>
  </div>
</template>

<script>
  import TextModerationRulesManagement from "@/screens/server-details/components/TextModerationRulesManagement";

  export default {
    components: {
      TextModerationRulesManagement
    },
    props: {
      server: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss">

</style>
